.VideoDetailContainer {
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: top;
}
.VideoDetailContainer.darkMode {
  background-color: black;
}
.VideoDetailContainer.darkMode > .suggestedVideosBox > span {
  color: white;
}

.playVideoBox {
  height: 600px;
  width: 950px;
  border: 1px solid rgba(145, 145, 145, 0.275);
  position: sticky;
  top: 80px;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgb(82, 82, 82);
}

.playVideoBox > .mainVideo {
  height: 80%;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 255, 0.275);
  overflow: hidden;
}
.playVideoBox > .videoDetailBox {
  background-color: aliceblue;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10px;
}
.playVideoBox > .videoDetailBox.darkMode {
  background-color: rgb(52, 52, 52);
  color: white;
}

.videoDetailBox > .details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.videoDetailBox > .details > .profileImg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid blue;
  margin-right: 5px;
  text-decoration: none;
  color: black;
}
.videoDetailBox > .details > .profileImg > img {
  width: 100%;
}
.videoDetailBox > .details button {
  padding: 5px 10px;
  border-radius: 25px;
  margin: 5px;
  background-color: rgb(196, 210, 255);
  border: none;
  outline: none;
  box-shadow: 0px 0px 1px blue;
  font-family: "Croissant One", cursive;
  font-size: 1vmax;
}
.videoDetailBox.darkMode > .details button {
  background-color: rgb(20, 20, 20);
  color: white;
}

.suggestedVideosBox {
  width: 400px;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.suggestedVideosBox > .videoCard {
  margin: 5px 0;
}

@media screen and (max-width: 1430px) {
  .VideoDetailContainer {
    flex-direction: column;
    align-items: center;
    z-index: -1;
  }
  .playVideoBox {
    position: relative;
    width: 95%;
    height: 105vmin;
    top: 10px;
  }
  .playVideoBox > .mainVideo {
    height: 80%;
    width: 100%;
    border-radius: 5px;
  }

  .suggestedVideosBox {
    width: 95%;
    padding: 20px 0;
  }
  .suggestedVideosBox > .videoCard {
    margin: 10px;
  }
}
@media screen and (max-width: 800px) {
  .playVideoBox {
    position: relative;
    width: 95%;
    width: 100%;
    height: 72vmin;
    position: sticky;
    top: 52px;
  }
  .playVideoBox > .mainVideo {
    width: 100%;
    border-radius: 5px;
  }

  .suggestedVideosBox {
    width: 95%;
    padding: 20px 0;
  }
}
@media screen and (max-width: 600px) {
  .playVideoBox {
    height: 85vmin;
  }
  .playVideoBox > .mainVideo {
    height: 68%;
  }
  .playVideoBox > .videoDetailBox {
    height: 32%;
  }
  .videoDetailBox > p {
    font-size: 0.8rem;
  }
  .videoDetailBox > .details > span {
    font-size: 0.8rem;
  }
  .videoDetailBox > .details > .profileImg {
    height: 30px;
    width: 30px;
  }
  .videoDetailBox > .details button {
    padding: 4px 5px;
    border-radius: 25px;
    margin: 2px;
    font-size: 1.2vmax;
  }
  .suggestedVideosBox > .videoCard {
    margin: 5px 0;
    padding: 0;
    width: 100%;
  }
}
