* {
  margin: 0;
  padding: 0;
}

/* Navbar................... */

.Navbar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 80px 0px 30px;
  background-color: rgb(255, 255, 255);
  z-index: 10;
}

.Navbar > .mainLogo {
  width: 85px;
  cursor: pointer;
}
.Navbar.darkMode > .mainLogo {
  width: 85px;
}
.Navbar > .mainLogo > img {
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 6px;
  padding: 3px;
  padding-left: 5px;
  box-shadow: 0px 0px 2px rgb(0, 0, 214);
}
.Navbar.darkMode > .mainLogo > img {
  background-color: white;
  border-radius: 6px;
  padding: 2px;
  padding-left: 5px;
}

.searchBox {
  width: 75%;
  height: 5vh;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  box-shadow: 0px 0px 1px blue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBox > input {
  flex-grow: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.05rem;
  padding: 0 15px;
  font-family: "Croissant One", cursive;
  background-color: #f6fbff !important;
  border-radius: 50px;
}
.searchBox > button {
  width: 60px;
  border-radius: 50px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  background-color: rgb(11, 0, 164);
  color: white;
  height: 100%;
  border: 1px solid rgb(97, 97, 97);
}

.searchBox > button:hover {
  background-color: rgb(9, 0, 130);
}

.nightMode > button {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
}

.mainSearchInput.darkMode {
  background-color: #474747 !important;
}
/* videoCard ........................... */

.videoCard,
.channelCard {
  height: 290px;
  width: 380px;
  border: 1px solid rgba(0, 0, 255, 0.275);
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
  background-color: aliceblue;
}
.videoCard:hover,
.channelCard:hover {
  background-color: rgb(223, 237, 249);
}
.videoCard.darkMode,
.channelCard.darkMode {
  background-color: rgb(65, 65, 65);
}
.videoCard.darkMode:hover,
.channelCard.darkMode:hover {
  background-color: rgb(55, 55, 55);
}

.videoCard > .imageBox {
  height: 73%;
  width: 100%;
  border: 1px solid rgba(0, 0, 255, 0.278);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageBox > img {
  width: 100%;
  align-self: center;
}
.videoCard > .videoText {
  height: 27%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoCard > .videoText > p {
  color: rgb(70, 70, 70);
  text-align: center;
  font-size: 0.8rem;
}
.videoCard.darkMode > .videoText > p {
  color: rgb(209, 209, 209);
}
.videoCard > .videoText > .channelName {
  font-size: 0.9rem;
}
.videoCard.darkMode > .videoText > .channelName {
  color: rgb(235, 235, 235);
}
.videoCard > .videoText > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.videoCard > .videoText > div > svg {
  color: rgb(133, 133, 133);
  font-size: 0.8rem;
  margin: 3px;
}
/* channel card .............*/
.channelCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.channelCard.darkMode {
  color: white;
}
.channelCard > .channelImageBox {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgb(199, 199, 250);
}
.channelCard > .channelImageBox > img {
  width: 100%;
}
.channelCard > .channelText {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channelCard > .channelText > svg {
  color: rgb(133, 133, 133);
  font-size: 0.9rem;
  margin: 3px;
}

@media screen and (max-width: 810px) {
  .videoCard {
    height: 75vmin;
    width: 95%;
  }

  .videoCard > .imageBox {
    height: 69.5%;
  }
}
@media screen and (max-width: 660px) {
  .searchBox > input {
    padding: 0 7px;
    font-size: 0.9rem;
  }

  .videoCard {
    height: 75vmin;
    width: 95%;
  }
  .Navbar {
    padding: 10px;
    height: 5vh;
  }
  .Navbar > .mainLogo {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Navbar.darkMode > .mainLogo {
    width: 65px;
  }
  .searchBox {
    height: 4vh;
    margin: 5px;
  }

  .searchBox > button svg {
    font-size: 0.9rem;
  }
  .nightMode > button svg {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 510px) {
  .searchBox > input {
    flex-grow: 1;
    border-radius: 50px;
    width: 78%;
  }
  .searchBox > button {
    width: 50px;
  }
  .videoCard > .videoText > p {
    margin-bottom: 3px;
    margin: unset;
  }
  .videoCard > .videoText > .channelName {
    font-size: 0.9rem;
    margin: unset;
  }
  .Navbar > .mainLogo > img {
    box-shadow: 0px 0px 2px rgba(0, 0, 214, 0.573);
  }
}
