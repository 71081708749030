.chnnelVideos {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.chnnelVideos.darkMode {
  background-color: black;
}
.chnnelVideos > .chnnelCard,
.chnnelVideos > .videoCard {
  margin: 5px;
}

.seeMore {
  padding: 10px;
  font-size: 1rem;
  width: 120px;
  background-color: white;
  box-shadow: 0px 0px 1px rgb(0, 0, 148);
  color: rgb(1, 1, 165);
  border: none;
  outline: none;
  margin: auto;
  border-radius: 3px;
  cursor: pointer;
  font-family: "Croissant One", cursive;
}
.seeMore:hover {
  color: black;
}

.channeProfilelInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.channeProfilelInfo.darkMode {
  background-color: black;
  color: white;
}
.channeProfilelInfo > .profileImg {
  height: 120px;
  border-radius: 50%;
  width: 120px;
  overflow: hidden;
  border: 3px solid rgb(119, 119, 254);
}
.channeProfilelInfo > .profileImg > img {
  width: 100%;
}
.channeProfilelInfo > p {
  margin: 3px 0;
}

.channeProfilelInfo:nth-child(2) button {
  border: none;
  padding: 5px 8px;
  margin: 3px;
  border-radius: 15px;
  background-color: rgb(221, 221, 253);
  border: 1px solid rgb(105, 105, 105);
  font-size: 1rem;
  font-family: "Croissant One", cursive;
}
.channeProfilelInfo.darkMode:nth-child(2) button {
  background-color: rgb(65, 65, 65);
  color: white;
}

@media screen and (max-width: 500px) {
  .channeProfilelInfo:nth-child(2) button {
    font-size: 0.7rem;
  }
}
